import * as React from "react"
import LayoutBlogDiversity from "../components/LayoutBlogDiversity"
import BlogPage2 from "../components/BlogPage/BlogPage2"
import AccordianDropdown from "../components/BlogPage/AccordianDropdown"
import CommentBox from '../components/BlogPage/CommentBox'
import { Helmet } from "react-helmet";

function blog({ data }) {
  return (
    <LayoutBlogDiversity>
      <Helmet>
        {/* <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/3EMP1aRMHimHfO5MQ7qoZ8/2659df6bfd8cd6ff4b343ec787a02a93/blog-post.png" /> */}
        <meta property="og:title" content="Diversity and Inclusion in Luxury Leadership" />
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/122ZsIVfZMi9nYxKYppaqI/20a23eef50e015e6f0e23fe28e625292/leena_press.png" />
        <meta property="og:description" content="How Diverse is Leadership Luxury’s Top Companies? Discover Diversity and Inclusion in Luxury Leadership" />
        <meta property="og:url" content="https://thefreshkid.com/luxury-diversity-and-inclusion" />
      </Helmet>
      <BlogPage2/>
      <AccordianDropdown/>
      {/* <CommentBox /> */}
    </LayoutBlogDiversity>
  )
}

export default blog




