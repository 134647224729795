import React, { useEffect, useRef, useState } from "react";
import "../scss/BlogPage2.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Helmet } from "react-helmet";
import FK from "./FK.png"; 
import email from "./email_black.png";
import leenanair from "./leena3.png";
import yemihead from "./yemi_head.png";

export default function BlogPage2() {
  const data = useStaticQuery(graphql`
  query MyQueryBlogPage2 {
    allContentfulHeroImage (filter:{id:{eq:"e2d6304e-de9d-5fb6-bcd0-690ab99b68af"}}){
      edges {
        node {
          blogCategory
          blogTitle
          date
          readTime
          author {
            firstName
          }
          id
          socialMediaIcons {
            linkedInIcon {
              image {
                file {
                  url
                }
              }
            }
            facebookIcon {
              image {
                file {
                  url
                }
              }
            }
          }
          heroImage {
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
}
  `);


  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const breakpoint = 2000; // Adjust this value to determine when the icons should disappear

      if (scrollPosition > breakpoint) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const options = {
    renderText: (text) => {
      // Split the text based on the word you want (e.g., "breakpoint-word")
      const breakpointWord = "calledp";
      const splitText = text.split(breakpointWord);
  
      // Create an array of React components
      const reactComponents = splitText.flatMap((text, index) => [
        // Add a <br> tag after the word in all elements except the last one
        <React.Fragment key={`text-${index}`}>{text}</React.Fragment>,
        index !== splitText.length - 1 && <br key={`br-${index}`} />,
      ]);
  
      return <React.Fragment>{reactComponents}</React.Fragment>;
    },
  };
  

  const [isSocialMediaBlogVisible, setIsSocialMediaBlogVisible] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 6500; // Adjust this value to determine when to hide socialMediaBlog

      if (scrollPosition > scrollThreshold) {
        setIsSocialMediaBlogVisible(false);
      } else {
        setIsSocialMediaBlogVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const [activeSection, setActiveSection] = useState("");


  



  const [isContentsVisible, setIsContentsVisible] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  
    const showThreshold = 1100; // Adjust this value to determine when to show the contents
    const hideThreshold = 6500; // Adjust this value to determine when to hide the contents
    let isScrollingDown = false;
  
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
  
      if (scrollPosition > showThreshold && !isScrollingDown) {
        setIsContentsVisible(true);
        isScrollingDown = true;
      } else if (scrollPosition <= showThreshold && isScrollingDown) {
        setIsContentsVisible(false);
        isScrollingDown = false;
      }
  
      // Add logic to hide content near the bottom
      if (scrollPosition > hideThreshold) {
        setIsContentsVisible(false);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  
  return (
    <div className="contentFeatureBlogD">
      <Helmet>
        {/* <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/3EMP1aRMHimHfO5MQ7qoZ8/2659df6bfd8cd6ff4b343ec787a02a93/blog-post.png" /> */}
        <meta property="og:title" content="Diversity and Inclusion in Luxury Leadership" />
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/122ZsIVfZMi9nYxKYppaqI/20a23eef50e015e6f0e23fe28e625292/leena_press.png" />
        <meta property="og:description" content="How Diverse is Leadership in Luxury’s Top Companies?" />
        <meta property="og:url" content="https://thefreshkid.com/luxury-diversity-and-inclusion" />
      </Helmet>
      <div className={"textContainerBlogD"}>
        <p className={"categoryBlogD"}>
          {data.allContentfulHeroImage.edges[0].node.blogCategory}
        </p>
        <p className={"readTimeBlogD"}>
          {data.allContentfulHeroImage.edges[0].node.readTime}
        </p>
        <p className={"titleBlogD"}>
          {data.allContentfulHeroImage.edges[0].node.blogTitle}
        </p>
        <div className={"accordianBlogImage"}>
        <img
          className={"bigImage"}
          alt={""}
          key={""}
          src={
            yemihead
          }
        />
      </div>
        <p className={"authorBlogD"}>
          {data.allContentfulHeroImage.edges[0].node.author.firstName}
        </p>
        <p className={"dateBlogD"}>
          {data.allContentfulHeroImage.edges[0].node.date}
        </p>
      </div>
      <div className="SocialMediaBlogD" data-aos="fade-up">
      {isSocialMediaBlogVisible && (
          <>
            <div
              className="ShareD"
            >
              Share
            </div>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url=thefreshkid.com/the-fresh-kid/">
              <img
                className="SocialIconBlogD"
                alt=""
                key=""
                src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.linkedInIcon.image.file.url}
              />
            </a>

            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthefreshkid.com/the-fresh-kid/" target="_blank">
            <img
                className="SocialIconBlogD"
                alt=""
                key=""
                src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.facebookIcon.image.file.url}
              />
            </a>

            <a href="mailto:?subject=From thefreshkid.com: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry&body=Check out this article: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry www.thefreshkid.com/the-fresh-kid/" target="_blank">
              <img
                className="SocialIconBlog1D"
                alt=""
                key=""
                src={email}
                style={{ marginRight: '100px' }} 
              />
            </a>
          </>
        )}
      </div>

      {/* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_sticky_social_bar */}

      <div className={"imageContainerBlogD"}>
        <img
          className={"bigImageD"}
          alt="luxury diversity and inclusion"
          key={""}
          src={
            leenanair
          }
        />
      </div>
    </div>
  );
}

