import React, { useState, useEffect } from "react";
import "../scss/PracticeBar.scss";
import NewsletterBar from "../NewsletterBar"
import Footer from "../Footer"
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Helmet } from "react-helmet";
import DiversityComments from './DiversityComments'


// const AccordionDropdown = () => {
//     const [activeIndexes, setActiveIndexes] = useState([]);
//     const [expandedSection, setExpandedSection] = useState(false);
  
//     const handleClick = (index) => {
//       if (activeIndexes.includes(index)) {
//         setActiveIndexes(activeIndexes.filter((i) => i !== index));
//         setExpandedSection(false);
//       } else {
//         setActiveIndexes([...activeIndexes, index]);
//         setExpandedSection(true);
//       }
//     };

const AccordionDropdown = () => {

    const data = useStaticQuery(graphql`
    query MyQueryDiversity {
        intro: allContentfulTextBlock(filter:{id:{eq:"55ce43a9-f370-59bd-977e-fef2a1ab46c2"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text1: allContentfulTextBlock(filter:{id:{eq:"dd8b73b7-b608-570a-90e4-b213b71beb36"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text2: allContentfulTextBlock(filter:{id:{eq:"333609e2-21fe-5062-a340-3b50af010673"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text3: allContentfulTextBlock(filter:{id:{eq:"3e205ed1-55ee-599c-a87d-9132b1d810c4"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text4: allContentfulTextBlock(filter:{id:{eq:"f1eccca1-ea75-5827-a63d-2cf42e049d80"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text5: allContentfulTextBlock(filter:{id:{eq:"90fd963b-c20f-5573-8aa5-1a06525dddff"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text6: allContentfulTextBlock(filter:{id:{eq:"61e5e03f-b814-5950-ad9e-a1314d57c56b"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text7: allContentfulTextBlock(filter:{id:{eq:"371b8a13-f01b-58ef-8df2-369c80d6510b"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text8: allContentfulTextBlock(filter:{id:{eq:"137753df-ac5b-58d7-8d31-d36a550d34c8"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text9: allContentfulTextBlock(filter:{id:{eq:"4a7a2c7e-26a5-59ab-9374-749d8af917bf"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text10: allContentfulTextBlock(filter:{id:{eq:"24e61ce6-619c-5157-9070-96e2df5e1688"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text11: allContentfulTextBlock(filter:{id:{eq:"4111fd86-96f0-50e5-8083-623f5bb1f850"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text12: allContentfulTextBlock(filter:{id:{eq:"c25cb43b-5d82-5d62-95de-cccb69127854"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text13: allContentfulTextBlock(filter:{id:{eq:"997576a1-8e7b-56d4-926d-dad38602e4f3"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
        text14: allContentfulTextBlock(filter:{id:{eq:"d558ac77-e2c9-55d0-85bf-0f9790d5572a"}}) {
            edges {
              node {
                bodyText {
                  raw
                }
                title
                id
              }
            }
        }
    }
  `);


  const intro = data.intro.edges[0].node;
  const accordian1 = data.text1.edges[0].node;
  const accordian2 = data.text2.edges[0].node;
  const accordian3 = data.text3.edges[0].node;
  const accordian4 = data.text4.edges[0].node;
  const accordian5 = data.text5.edges[0].node;
  const accordian6 = data.text6.edges[0].node;
  const accordian7 = data.text7.edges[0].node;
  const accordian8 = data.text8.edges[0].node;
  const accordian9 = data.text9.edges[0].node;
  const accordian10 = data.text10.edges[0].node;
  const accordian11 = data.text11.edges[0].node;
  const accordian12 = data.text12.edges[0].node;
  const accordian13 = data.text13.edges[0].node;
  const accordian14 = data.text14.edges[0].node;



  const options = {
    renderText: (text) => {
      // Split the text based on the word you want (e.g., "breakpoint-word")
      const breakpointWord = "calledp";
      const splitText = text.split(breakpointWord);
  
      // Create an array of React components
      const reactComponents = splitText.flatMap((text, index) => [
        // Add a <br> tag after the word in all elements except the last one
        <React.Fragment key={`text-${index}`}>{text}</React.Fragment>,
        index !== splitText.length - 1 && <br key={`br-${index}`} />,
      ]);
  
      return <React.Fragment>{reactComponents}</React.Fragment>;
    },
  };



    const [activeIndexes, setActiveIndexes] = useState([]);
  
    const handleClick = (index) => {
      if (activeIndexes.includes(index)) {
        setActiveIndexes(activeIndexes.filter((i) => i !== index));
      } else {
        setActiveIndexes([...activeIndexes, index]);
      }
    };





    const [activeSection, setActiveSection] = useState("");

    const sectionIds = [
      "Introduction",
      "Insights Summary",
      "Gender Insights",
      "Education",
      "Age",
    ];
  
    useEffect(() => {
      const handleScroll = () => {
        const sectionOffsets = sectionIds.map((sectionId) => {
          const sectionElement = document.getElementById(sectionId);
          return {
            id: sectionId,
            offsetTop: sectionElement.offsetTop,
            offsetBottom: sectionElement.offsetTop + sectionElement.offsetHeight,
          };
        });
    
        const currentScrollPosition = window.scrollY + window.innerHeight / 2;
    
        for (const { id, offsetTop, offsetBottom } of sectionOffsets) {
          if (currentScrollPosition >= offsetTop && currentScrollPosition <= offsetBottom) {
            setActiveSection(id);
            break;
          }
        }
      };
    
      window.addEventListener("scroll", handleScroll);
    
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);







    const [isContentsVisible, setIsContentsVisible] = useState(false);

    useEffect(() => {
      Aos.init({ duration: 2000 });
    
      const showThreshold = 1100; // Adjust this value to determine when to show the contents
      const hideThreshold = 15000; // Adjust this value to determine when to hide the contents
      let isScrollingDown = false;
    
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
    
        if (scrollPosition > showThreshold && !isScrollingDown) {
          setIsContentsVisible(true);
          isScrollingDown = true;
        } else if (scrollPosition <= showThreshold && isScrollingDown) {
          setIsContentsVisible(false);
          isScrollingDown = false;
        }
    
        // Add logic to hide content near the bottom
        if (scrollPosition > hideThreshold) {
          setIsContentsVisible(false);
        }
      };
    
      window.addEventListener("scroll", handleScroll);
    
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);





  return (
    <>
      <Helmet>
        <title>Luxury Diversity and Inclusion</title>
        <meta name="description" content="How  Diverse is Leadership Luxury’s Top Companies? Discover Diversity and Inclusion in Luxury Leadership" />
        <meta name="keywords" content="Luxury Diversity and Inclusion, Luxury Women in Leadership" />
        <h1>How Diverse is Leadership in Luxury's Top Companies?</h1>
      </Helmet>
    <div className={`all`}>
    <div className={`check`}>
    {/* <div className={`check ${expandedSection ? 'expanded' : ''}`}> */}



    {isContentsVisible && (
        <div>
          <div className="contentsListad">
            <p className="contentad">Contents</p>
            {sectionIds.map((sectionId) => (
              <a
                key={sectionId}
                href={`#${sectionId}`}
                className={activeSection === sectionId ? "active" : ""}
              >
                {sectionId}
              </a>
            ))}
          </div>
        </div>
      )}






      <div id="Introduction" className="intro">
        <div className="AccordianTitleIntro">
            {intro.title}
        </div>
        <div id="Introduction" className="AccordianBodyIntro">
            {documentToReactComponents(JSON.parse(intro.bodyText.raw),options)}
        </div>
      </div>



        <div>
        <Helmet>
          <h2>Diversity and Inclusion in Luxury Leadership Insights Summary</h2>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(0) ? 'active' : ''}`}
          onClick={() => handleClick(0)}
        >
          <span id="Insights Summary" className="accordiantitle1">{accordian1.title}</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{maxHeight: activeIndexes.includes(0) ? '5000vw' : '0'}}>

            <div className="accordiancontent1">
                {/* <ul>
                    <li>Women occupy 28% of Luxury CEO roles in Luxury’s Top Companies</li>
                    <li>30 Women CEOs in Luxury’s Top Companies</li>
                    <li>28% of Luxury’s Top CEOs are Women</li>
                    <li>LVMH & Kering have the most Women CEOs</li>
                    <li>60% of Luxury’s Top Perfume & Cosmetics brands are led by Women</li>
                    <li>Only 4% of Luxury’s Top CEOs are People of Colour</li>
                    <li>75% of Luxury’s Top CEOs are University or College Educated</li>
                    <li>68% of University educated Top Luxury CEO’s studied Business</li>
                    <li>France most popular country where Top CEOs are educated</li>
                    <li>Insead most popular University & College where Luxury CEOs are educated</li>
                    <li>Average age of Top Luxury Woman CEO is 51 vs Men which is 49</li>
                    <li>Takes Top Luxury Women CEOs on average 3 years more to become CEO than Men</li>
                </ul> */}
                {documentToReactComponents(JSON.parse(accordian1.bodyText.raw),options)}
            </div>
        </div>
      </div>



                
      <div>
        <Helmet>
          <h2>Luxury C-Suite mostly Male</h2>
          <h3>There are 109 CEOs in the Top Luxury Companies</h3>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(1) ? 'active' : ''}`}
          onClick={() => handleClick(1)}
        >
          <span id="Gender Insights" className="accordiantitle2">{accordian2.title}</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{maxHeight: activeIndexes.includes(1) ? '5000vw' : '0'}}>

            <iframe src="https://e.infogram.com/1617671d-af89-4bd9-9152-1610b8f35c55?src=embed" title="Inclusive Leadership" width="900" height="850" scrolling="no" frameBorder="0" style={{ border: 'none' }} allowFullScreen className="chartFrame"></iframe>

            <div className="accordiancontent2">
                {/* <p>There are 121  CEOs in the Top Luxury Companies that include LVMH owner of brands like Loewe & Louis Vuitton and Kering which owns Saint Lauren.</p>
                <p>28% of these Luxury CEOs are women.  This is inline with the wider business world average. In 2019 <a href="https://leftronic.com/blog/ceo-statistics/#:~:text=Regardless%20of%20these%2050%2F50,%2C%20were%20at%20only%2024%25.">29% of all global senior management roles were filled by women.</a></p>
                <p>Luxury’s Top Companies are publicly listed companies and of the 500 largest public listed companies in the world or the S&P 500 Women CEOs have delivered higher profits and returns for investors over the last 10 years.</p>
                <p>With Luxury’s Top Companies and the industry as a sector slowing down recently. Are more Luxury Women CEOs the answer to growth?</p> */}
                {documentToReactComponents(JSON.parse(accordian2.bodyText.raw),options)}
            </div>
        </div>
      </div>





      <div>
        <Helmet>
          <h2>Luxury’s Top Women CEOs</h2>
          <h3>In 2023 there are 30 women CEOs at the Top Luxury Companies</h3>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(2) ? 'active' : ''}`}
          onClick={() => handleClick(2)}
        >
          <span className="accordiantitle3">{accordian3.title}</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{maxHeight: activeIndexes.includes(2) ? '5000vw' : '0'}}>

            <iframe src="https://e.infogram.com/a44ab89f-9bed-479a-9603-de7826126809?src=embed" title="Luxury Women in leadership" width="900" height="950" scrolling="no" frameBorder="0" style={{ border: 'none' }} allowFullScreen className="chartFrame"></iframe>


            <div className="accordiancontent3">
                {/* <p>There are 121  CEOs in the Top Luxury Companies that include LVMH owner of brands like Loewe & Louis Vuitton and Kering which owns Saint Lauren.</p>
                <p>28% of these Luxury CEOs are women.  This is inline with the wider business world average. In 2019 <a href="https://leftronic.com/blog/ceo-statistics/#:~:text=Regardless%20of%20these%2050%2F50,%2C%20were%20at%20only%2024%25.">29% of all global senior management roles were filled by women.</a></p>
                <p>Luxury’s Top Companies are publicly listed companies and of the 500 largest public listed companies in the world or the S&P 500 Women CEOs have delivered higher profits and returns for investors over the last 10 years.</p>
                <p>With Luxury’s Top Companies and the industry as a sector slowing down recently. Are more Luxury Women CEOs the answer to growth?</p> */}
                {documentToReactComponents(JSON.parse(accordian3.bodyText.raw),options)}
            </div>

        </div>
      </div>




      <div>
        <Helmet>
          <h2>LVMH Lead with Women CEOs</h2>
          <h3>LVMH has the most amount of Women CEOs. 64 in total of which 19 are women and 45 are Men</h3>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(3) ? 'active' : ''}`}
          onClick={() => handleClick(3)}
        >
          <span className="accordiantitle4">{accordian4.title}</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{maxHeight: activeIndexes.includes(3) ? '5000vw' : '0'}}>

            <iframe src="https://e.infogram.com/fa91bcea-753f-4a9e-8631-489ac7f37766?src=embed" title="Luxury Women CEOs" width="900" height="950" scrolling="no" frameBorder="0" style={{ border: 'none' }} allowFullScreen className="chartFrame"></iframe>


            <div className="accordiancontent4">
                {/* <p>There are 121  CEOs in the Top Luxury Companies that include LVMH owner of brands like Loewe & Louis Vuitton and Kering which owns Saint Lauren.</p>
                <p>28% of these Luxury CEOs are women.  This is inline with the wider business world average. In 2019 <a href="https://leftronic.com/blog/ceo-statistics/#:~:text=Regardless%20of%20these%2050%2F50,%2C%20were%20at%20only%2024%25.">29% of all global senior management roles were filled by women.</a></p>
                <p>Luxury’s Top Companies are publicly listed companies and of the 500 largest public listed companies in the world or the S&P 500 Women CEOs have delivered higher profits and returns for investors over the last 10 years.</p>
                <p>With Luxury’s Top Companies and the industry as a sector slowing down recently. Are more Luxury Women CEOs the answer to growth?</p> */}
                {documentToReactComponents(JSON.parse(accordian4.bodyText.raw),options)}
            </div>

        </div>
      </div>




      <div>
        <Helmet>
          <h2>Luxury Perfume and Cosmetics is led by Female CEOs</h2>
          <h3>If you are a Women CEO at a Top Luxury company you are much more likely to be working in Perfume and Cosmetics.</h3>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(4) ? 'active' : ''}`}
          onClick={() => handleClick(4)}
        >
          <span className="accordiantitle5">{accordian5.title}</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{maxHeight: activeIndexes.includes(4) ? '5000vw' : '0'}}>

            <iframe src="https://e.infogram.com/65fbc648-b3bb-4af2-814d-217b861bcd67?src=embed" title="Luxury Women CEOs" width="900" height="950" scrolling="no" frameBorder="0" style={{ border: 'none' }} allowFullScreen className="chartFrame"></iframe>


            <div className="accordiancontent5">
                {/* <p>There are 121  CEOs in the Top Luxury Companies that include LVMH owner of brands like Loewe & Louis Vuitton and Kering which owns Saint Lauren.</p>
                <p>28% of these Luxury CEOs are women.  This is inline with the wider business world average. In 2019 <a href="https://leftronic.com/blog/ceo-statistics/#:~:text=Regardless%20of%20these%2050%2F50,%2C%20were%20at%20only%2024%25.">29% of all global senior management roles were filled by women.</a></p>
                <p>Luxury’s Top Companies are publicly listed companies and of the 500 largest public listed companies in the world or the S&P 500 Women CEOs have delivered higher profits and returns for investors over the last 10 years.</p>
                <p>With Luxury’s Top Companies and the industry as a sector slowing down recently. Are more Luxury Women CEOs the answer to growth?</p> */}
                {documentToReactComponents(JSON.parse(accordian5.bodyText.raw),options)}
            </div>

        </div>
      </div>



      <div>
        <Helmet>
          <h2>Only 5% of Luxury’s Top CEOs are People of Colour</h2>
          <h3>Out of the 109 Top Luxury CEOs there are only 5 people of colour (5% of total) or those that are not white caucasians.</h3>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(5) ? 'active' : ''}`}
          onClick={() => handleClick(5)}
        >
          <span className="accordiantitle6">{accordian6.title}</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{
            maxHeight: activeIndexes.includes(5) ? '5000px' : '0'
          }}
        >
            <iframe src="https://e.infogram.com/c5634311-f396-4414-8295-6a1e09b7e2b4?src=embed" title="Luxury equity diversity and inclusion" width="900" height="950" scrolling="no" frameBorder="0" style={{ border: 'none' }} allowFullScreen className="chartFrame"></iframe>

            <div className="accordiancontent6">
                {/* <p>There are 121  CEOs in the Top Luxury Companies that include LVMH owner of brands like Loewe & Louis Vuitton and Kering which owns Saint Lauren.</p>
                <p>28% of these Luxury CEOs are women.  This is inline with the wider business world average. In 2019 <a href="https://leftronic.com/blog/ceo-statistics/#:~:text=Regardless%20of%20these%2050%2F50,%2C%20were%20at%20only%2024%25.">29% of all global senior management roles were filled by women.</a></p>
                <p>Luxury’s Top Companies are publicly listed companies and of the 500 largest public listed companies in the world or the S&P 500 Women CEOs have delivered higher profits and returns for investors over the last 10 years.</p>
                <p>With Luxury’s Top Companies and the industry as a sector slowing down recently. Are more Luxury Women CEOs the answer to growth?</p> */}
                {documentToReactComponents(JSON.parse(accordian6.bodyText.raw),options)}
            </div>
        </div>
      </div>



      <div>
        <Helmet>
          <h2>82% of Top Luxury CEOs are University or College Educated</h2>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(6) ? 'active' : ''}`}
          onClick={() => handleClick(6)}
        >
          <span id="Education" className="accordiantitle7">{accordian7.title}</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{
            maxHeight: activeIndexes.includes(6) ? '5000px' : '0'
          }}
        >
            {/* <iframe src="https://e.infogram.com/c5634311-f396-4414-8295-6a1e09b7e2b4?src=embed" title="Bar Chart" width="900" height="900" scrolling="no" frameBorder="0" style={{ border: 'none' }} allowFullScreen className="chartFrame"></iframe> */}

            <div className="accordiancontent7">
                {/* <p>There are 121  CEOs in the Top Luxury Companies that include LVMH owner of brands like Loewe & Louis Vuitton and Kering which owns Saint Lauren.</p>
                <p>28% of these Luxury CEOs are women.  This is inline with the wider business world average. In 2019 <a href="https://leftronic.com/blog/ceo-statistics/#:~:text=Regardless%20of%20these%2050%2F50,%2C%20were%20at%20only%2024%25.">29% of all global senior management roles were filled by women.</a></p>
                <p>Luxury’s Top Companies are publicly listed companies and of the 500 largest public listed companies in the world or the S&P 500 Women CEOs have delivered higher profits and returns for investors over the last 10 years.</p>
                <p>With Luxury’s Top Companies and the industry as a sector slowing down recently. Are more Luxury Women CEOs the answer to growth?</p> */}
                {documentToReactComponents(JSON.parse(accordian7.bodyText.raw),options)}
            </div>
        </div>
      </div>
      

      <div>
        <Helmet>
          <h2>67% of University educated Top Luxury CEOs studied Business</h2>
          <h3>Overwhelmingly Business is the most studied subject by Top Luxury CEOs at college and university.</h3>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(7) ? 'active' : ''}`}
          onClick={() => handleClick(7)}
        >
          <span className="accordiantitle8">{accordian8.title}</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{
            maxHeight: activeIndexes.includes(7) ? '5000px' : '0'
          }}
        >

            <iframe src="https://e.infogram.com/9fce4c81-6dfa-482a-9e68-33ab38c95c38?src=embed" title="Luxury leadership" width="900" height="950" scrolling="no" frameBorder="0" style={{ border: 'none' }} allowFullScreen className="chartFrame"></iframe>

            <div className="accordiancontent8">
                {/* <p>There are 121  CEOs in the Top Luxury Companies that include LVMH owner of brands like Loewe & Louis Vuitton and Kering which owns Saint Lauren.</p>
                <p>28% of these Luxury CEOs are women.  This is inline with the wider business world average. In 2019 <a href="https://leftronic.com/blog/ceo-statistics/#:~:text=Regardless%20of%20these%2050%2F50,%2C%20were%20at%20only%2024%25.">29% of all global senior management roles were filled by women.</a></p>
                <p>Luxury’s Top Companies are publicly listed companies and of the 500 largest public listed companies in the world or the S&P 500 Women CEOs have delivered higher profits and returns for investors over the last 10 years.</p>
                <p>With Luxury’s Top Companies and the industry as a sector slowing down recently. Are more Luxury Women CEOs the answer to growth?</p> */}
                {documentToReactComponents(JSON.parse(accordian8.bodyText.raw),options)}
            </div>
        </div>
      </div>


      <div>
        <Helmet>
          <h2>France most popular country where Top Luxury CEOs are educated</h2>
          <h3>49% of the Top Luxury CEOs with a college or University education were educated in France.</h3>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(8) ? 'active' : ''}`}
          onClick={() => handleClick(8)}
        >
          <span className="accordiantitle9">{accordian9.title}</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{
            maxHeight: activeIndexes.includes(8) ? '5000px' : '0'
          }}
        >
            <iframe src="https://e.infogram.com/51acd64b-90c4-47a1-8cf5-0bc8c7d8768d?src=embed" title="Luxury leadership" width="900" height="770" scrolling="no" frameBorder="0" style={{ border: 'none' }} allowFullScreen className="chartFrame"></iframe>
            <div className="accordiancontent9">
                {/* <p>There are 121  CEOs in the Top Luxury Companies that include LVMH owner of brands like Loewe & Louis Vuitton and Kering which owns Saint Lauren.</p>
                <p>28% of these Luxury CEOs are women.  This is inline with the wider business world average. In 2019 <a href="https://leftronic.com/blog/ceo-statistics/#:~:text=Regardless%20of%20these%2050%2F50,%2C%20were%20at%20only%2024%25.">29% of all global senior management roles were filled by women.</a></p>
                <p>Luxury’s Top Companies are publicly listed companies and of the 500 largest public listed companies in the world or the S&P 500 Women CEOs have delivered higher profits and returns for investors over the last 10 years.</p>
                <p>With Luxury’s Top Companies and the industry as a sector slowing down recently. Are more Luxury Women CEOs the answer to growth?</p> */}
                {documentToReactComponents(JSON.parse(accordian9.bodyText.raw),options)}
            </div>
        </div>
      </div>





      <div>
        <Helmet>
          <h2>Insead most popular Business School where Luxury CEOs are educated</h2>
          <h3>Luxury’s Top CEOs mostly go to Insead Business school to study post graduate masters and executive business MBA’s.</h3>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(9) ? 'active' : ''}`}
          onClick={() => handleClick(9)}
        >
          <span className="accordiantitle10">{accordian10.title}</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{
            maxHeight: activeIndexes.includes(9) ? '5000px' : '0'
          }}
        >

            <iframe src="https://e.infogram.com/3195c690-df2b-4ad3-9264-800a1de5717c?src=embed" title="Luxury mba" width="900" height="550" scrolling="no" frameBorder="0" style={{ border: 'none' }} allowFullScreen className="chartFrame"></iframe>


            <div className="accordiancontent10">
                {/* <p>There are 121  CEOs in the Top Luxury Companies that include LVMH owner of brands like Loewe & Louis Vuitton and Kering which owns Saint Lauren.</p>
                <p>28% of these Luxury CEOs are women.  This is inline with the wider business world average. In 2019 <a href="https://leftronic.com/blog/ceo-statistics/#:~:text=Regardless%20of%20these%2050%2F50,%2C%20were%20at%20only%2024%25.">29% of all global senior management roles were filled by women.</a></p>
                <p>Luxury’s Top Companies are publicly listed companies and of the 500 largest public listed companies in the world or the S&P 500 Women CEOs have delivered higher profits and returns for investors over the last 10 years.</p>
                <p>With Luxury’s Top Companies and the industry as a sector slowing down recently. Are more Luxury Women CEOs the answer to growth?</p> */}
                {documentToReactComponents(JSON.parse(accordian10.bodyText.raw),options)}
            </div>
        </div>
      </div>






      <div>
        <Helmet>
          <h2>Average age of Top Luxury Woman CEO is 52 vs Men which is 49</h2>
          <h3>The average age of a Top Luxury CEO is 51 with men marginally older than the women ceos</h3>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(10) ? 'active' : ''}`}
          onClick={() => handleClick(10)}
        >
          <span id="Age" className="accordiantitle11">{accordian11.title}</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{
            maxHeight: activeIndexes.includes(10) ? '5000px' : '0'
          }}
        >
            <iframe src="https://e.infogram.com/fb343731-3412-47a2-a810-34cf5e4d488c?src=embed" title="Luxury inclusion and diversity" width="900" height="600" scrolling="no" frameBorder="0" style={{ border: 'none' }} allowFullScreen className="chartFrame"></iframe>

            <div className="accordiancontent11">
                {/* <p>There are 121  CEOs in the Top Luxury Companies that include LVMH owner of brands like Loewe & Louis Vuitton and Kering which owns Saint Lauren.</p>
                <p>28% of these Luxury CEOs are women.  This is inline with the wider business world average. In 2019 <a href="https://leftronic.com/blog/ceo-statistics/#:~:text=Regardless%20of%20these%2050%2F50,%2C%20were%20at%20only%2024%25.">29% of all global senior management roles were filled by women.</a></p>
                <p>Luxury’s Top Companies are publicly listed companies and of the 500 largest public listed companies in the world or the S&P 500 Women CEOs have delivered higher profits and returns for investors over the last 10 years.</p>
                <p>With Luxury’s Top Companies and the industry as a sector slowing down recently. Are more Luxury Women CEOs the answer to growth?</p> */}
                {documentToReactComponents(JSON.parse(accordian11.bodyText.raw),options)}
            </div>
        </div>
      </div>


      <div>
        <Helmet>
          <h2>Takes Top Luxury Women CEOs on average 4 years more to become CEO than Men</h2>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(11) ? 'active' : ''}`}
          onClick={() => handleClick(11)}
        >
          <span className="accordiantitle12">{accordian12.title}</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{
            maxHeight: activeIndexes.includes(11) ? '5000px' : '0'
          }}
        >

            <iframe src="https://e.infogram.com/8c0a676a-b269-4273-ac4b-e3b8412c4173?src=embed" title="Luxury women in leadership" width="900" height="950" scrolling="no" frameBorder="0" style={{ border: 'none' }} allowFullScreen className="chartFrame"></iframe>

            <div className="accordiancontent12">
                {/* <p>There are 121  CEOs in the Top Luxury Companies that include LVMH owner of brands like Loewe & Louis Vuitton and Kering which owns Saint Lauren.</p>
                <p>28% of these Luxury CEOs are women.  This is inline with the wider business world average. In 2019 <a href="https://leftronic.com/blog/ceo-statistics/#:~:text=Regardless%20of%20these%2050%2F50,%2C%20were%20at%20only%2024%25.">29% of all global senior management roles were filled by women.</a></p>
                <p>Luxury’s Top Companies are publicly listed companies and of the 500 largest public listed companies in the world or the S&P 500 Women CEOs have delivered higher profits and returns for investors over the last 10 years.</p>
                <p>With Luxury’s Top Companies and the industry as a sector slowing down recently. Are more Luxury Women CEOs the answer to growth?</p> */}
                {documentToReactComponents(JSON.parse(accordian12.bodyText.raw),options)}
            </div>
        </div>
      </div>

      <div>
        <Helmet>
          <h2>Summary</h2>
          <h3>The top Luxury companies are very diverse when it comes to the women that lead their companies compared to the worlds biggest and most valuable companies.</h3>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(12) ? 'active' : ''}`}
          onClick={() => handleClick(12)}
        >
          <span className="accordiantitle13">Summary</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{
            maxHeight: activeIndexes.includes(12) ? '5000px' : '0'
          }}
        >

            <div className="accordiancontent13">
                {documentToReactComponents(JSON.parse(accordian13.bodyText.raw),options)}
            </div>
        </div>
      </div>


      <div>
        <Helmet>
          <h2>Methodology</h2>
        </Helmet>
        <button
          className={`accordion ${activeIndexes.includes(13) ? 'active' : ''}`}
          onClick={() => handleClick(13)}
        >
          <span className="accordiantitle14">Methodology</span>
        </button>
        <div className="line"></div>
        <div
          className="panel"
          style={{
            maxHeight: activeIndexes.includes(13) ? '5000px' : '0'
          }}
        >

            <div className="accordiancontent14">
                {documentToReactComponents(JSON.parse(accordian14.bodyText.raw),options)}
            </div>
        </div>
      </div>

      {/* <div className="outro">
        <p><strong>Luxury Leadership more diverse than most but needs to be more curious</strong></p>
        <p>I learnt so much about how luxury companies are lead putting this report together. My curiosity paid off and i know much more about who leads luxury companies.</p>
        <p>I believe a  diversity of people with different opinions, skillsets, backgrounds and life experiences leading your luxury company gives you more knowledge to solve more challenges.</p>
        <p>Luxury Leadership is much more diverse than most industries.</p>
        <p>But if Luxury wants to continue to thrive and grow it needs to be more curious with it's leadership choices which will make it more knowledgable and powerful.</p>
      </div> */}
    </div>
    <DiversityComments />
    <div style={{ marginTop: "30vw" }}>
        <NewsletterBar />
      </div>
      
      <Footer />
    </div>
    </>
  );
}

export default AccordionDropdown;