import React from "react";
import { DiscussionEmbed } from "disqus-react";
import "../scss/CommentBox.scss";

const CommentBox = () => {
  const disqusConfig = {
    shortname: "tfk-1",
    config: {
    },
    language: "en",
  };

  return (
    <div>
      <div className="comment-box-title">Join the Conversation</div>
      <div className="comment-box-container">
        <DiscussionEmbed {...disqusConfig} />
      </div>
    </div>
  );
};

export default CommentBox;
